export class WakeLock {
    constructor() {
      this.wakeLock = null;
    }

    async requestWakeLock() {
      try {
        if ('wakeLock' in navigator) {
          this.wakeLock = await navigator.wakeLock.request('screen');
          console.log("Wake Lock is active.");
          this.lockClosed();
          this.wakeLock.addEventListener('release', () => {
            console.log("Wake Lock has been released.");
            this.lockOpen();
          });
        } else {
          console.warn("Screen Wake Lock API is not supported.");
          this.lockBroken();
        }
      } catch (err) {
        console.error(`${err.name}, ${err.message}`);
        this.lockOpen();
      }
    }
  
    async releaseWakeLock() {
      if (this.wakeLock) {
        await this.wakeLock.release();
        this.wakeLock = null;
        console.log("Wake Lock released manually.");
        this.lockOpen();
      }
    }

    lockOpen() {
        document.getElementById("wake-lock").classList.remove("fa-lock");
        document.getElementById("wake-lock").classList.add("fa-lock-open");
    }

    lockBroken() {
        document.getElementById("wake-lock").classList.remove("fa-lock");
        document.getElementById("wake-lock").classList.remove("fa-lock-open");
        document.getElementById("wake-lock").classList.add("fa-lock-hashtag");
    }

    lockClosed() {
        document.getElementById("wake-lock").classList.remove("fa-lock-open");
        document.getElementById("wake-lock").classList.add("fa-lock");
    }

    autoReacquireOnVisibilityChange() {
      document.addEventListener("visibilitychange", async () => {
        if (this.wakeLock && document.visibilityState === "visible") {
          await this.requestWakeLock();
        }
      });
    }
}
